@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.border-hotel{
  box-shadow: 17px 20px 40px rgba(0, 0, 0, .21)
}



.live-whata{
  z-index: 1000;
  border-radius: 50%;
  background: linear-gradient(45deg, rgb(23, 201, 100), rgb(43, 255, 152));
  box-shadow: 0 0 10px rgba(43, 255, 152, 0.8), 0 0 20px rgba(43, 255, 152, 0.6),
    0 0 30px rgba(43, 255, 152, 0.4), 0 0 40px rgba(43, 255, 152, 0.2);
  animation: pulse 1.5s ease-in-out infinite, flicker 2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.letra{
  font-size: 14px;
    /* font-weight: 400; */
    overflow: hidden;
    /* text-align: center; */
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    height: 200px;
}